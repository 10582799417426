import { FC, useEffect, useState } from 'react';
import { LineItem } from 'shared/types/wishlist/LineItem';
import Button from 'components/commercetools-ui/atoms/button';
import HeartFilledIcon from 'components/icons/HeartFilledIcon';
import { useWishlist } from 'frontastic';

interface wishlistButtonProps {
  lineItem: LineItem;
  className?: string;
}

const WishlistButton: FC<wishlistButtonProps> = ({ lineItem, className }) => {
  const wishlist = useWishlist();
  const [onWishlist, setOnWishlist] = useState<boolean>(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (wishlist?.data?.lineItems) {
      const item = wishlist.data.lineItems.find(({ variant }) => variant?.sku === lineItem.variant?.sku);
      setOnWishlist(!!item);
    }
  }, [wishlist?.data?.lineItems, lineItem.variant?.sku]);

  const handleAddToWishList = async () => {
    if (wishlist?.data) await wishlist.addToWishlist(wishlist?.data, lineItem, 1);
  };

  const handleRemoveFromWishlist = async () => {
    if (wishlist?.data?.lineItems) {
      const item = wishlist.data.lineItems.find(({ variant }) => variant?.sku === lineItem.variant?.sku);
      if (item) await wishlist.removeLineItem(wishlist.data, item);
    }
  };

  const onClick = async () => {
    if (processing) return;
    setProcessing(true);
    if (onWishlist) {
      await handleRemoveFromWishlist();
    } else {
      await handleAddToWishList();
    }
    setProcessing(false);
  };

  return (
    <Button
      size="icon"
      className={className}
      variant={onWishlist ? 'addToWishlist' : 'removeFromWishlist'}
      onClick={onClick}
      data-test="add-to-wishlist"
    >
      <HeartFilledIcon />
    </Button>
  );
};

export default WishlistButton;
