function HeartFilledIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        d="M12.5 20.1769L11.3962 19.1846C9.73847 17.6807 8.36763 16.3884 7.28365 15.3077C6.19968 14.2269 5.34072 13.265 4.70675 12.4221C4.07277 11.5791 3.62982 10.8102 3.3779 10.1154C3.12597 9.42048 3 8.71536 3 7.99998C3 6.58076 3.47852 5.39263 4.43558 4.43558C5.39263 3.47853 6.58076 3 7.99998 3C8.87306 3 9.69806 3.20418 10.475 3.61253C11.2519 4.02086 11.9269 4.60643 12.5 5.36925C13.0731 4.60643 13.7481 4.02086 14.525 3.61253C15.3019 3.20418 16.1269 3 17 3C18.4192 3 19.6073 3.47853 20.5644 4.43558C21.5214 5.39263 22 6.58076 22 7.99998C22 8.71536 21.874 9.42048 21.6221 10.1154C21.3701 10.8102 20.9272 11.5791 20.2932 12.4221C19.6592 13.265 18.8019 14.2269 17.7211 15.3077C16.6403 16.3884 15.2679 17.6807 13.6038 19.1846L12.5 20.1769Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default HeartFilledIcon;
