import React, { FC, useEffect, useMemo, useState } from 'react';
import { Variant } from 'shared/types/product';
import { Product } from 'shared/types/product/Product';
import { LineItem } from 'shared/types/wishlist/LineItem';
import CartButton from 'components/commercetools-ui/atoms/cart-button';
import useTrack from 'components/commercetools-ui/atoms/cart-button/useTrack';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import Prices from 'components/commercetools-ui/organisms/product/product-tile/prices';
import WishlistButton from 'components/commercetools-ui/organisms/wishlist/components/wishlist-button';
import { useFormat } from 'helpers/hooks/useFormat';
import useVariantWithDiscount from 'helpers/hooks/useVariantWithDiscount';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import EnergyLabel, { EnergyLabelAttributes } from './components/energy-label';
import ProductCard from './components/product-card';
import ProductTileLabels from './components/product-tile-labels';
import PromotionalInfo from './components/promotional-info';

export type EnergyLabelType = 'A+++' | 'A++' | 'A+' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';

export type ProductLabels = {
  isDelivery: boolean;
  isGlutenFree: boolean;
  isMadeInPoland: boolean;
  energyLabel: EnergyLabelAttributes;
  isFreeShipping?: boolean;
  isOnPromotion?: boolean;
};

export interface ProductTileProps {
  product: Product;
  onClick?: () => void;
  isSearchResult?: boolean;
  disableQuickView?: boolean;
  disableWishlistButton?: boolean;
  disableVariants?: boolean;
  className?: string;
}

const ProductTile: FC<ProductTileProps> = ({
  product,
  onClick,
  isSearchResult = false,
  disableWishlistButton = false,
  className,
}) => {
  const { data, addItem, removeItem, updateItem } = useCart();
  const [quantity, setQuantity] = useState<number>(0);
  const [inputQuantity, setInputQuantity] = useState<number>(0);
  const [added, setAdded] = useState(false);
  const [removed, setRemoved] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const variantWithDiscount = useVariantWithDiscount(product.variants) as Variant;

  const variant = variantWithDiscount ?? product?.variants[0];

  const isOnStock = product?.variants[0]?.isOnStock ?? false;

  const productCardUrl = product?.variants[0]?.attributes?.productCardUrl;

  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const { trackAddToCartProduct, trackAddToCartLineItem } = useTrack();

  useEffect(() => {
    if (data?.lineItems) {
      const lineItem = data?.lineItems?.find((lineItem) => lineItem.variant?.sku === variant?.sku);
      if (lineItem?.count) setQuantity(lineItem.count);
      else setQuantity(0);
    }
  }, [data?.lineItems, quantity, variant?.sku]);

  const productToWishlistLineItem = useMemo<LineItem | undefined>(() => {
    if (product) {
      return {
        lineItemId: product.productId ?? '',
        productId: product.productId,
        name: product.name,
        count: 1,
        variant,
        addedAt: new Date(),
        _url: product._url,
      };
    }
  }, [product, variant]);

  const productLabels = useMemo(() => {
    return {
      isDelivery: isOnStock,
      isMadeInPoland: product?.variants[0]?.attributes?.madeInPoland?.value ?? false,
      isOnPromotion: !!(variant.strikeThroughPrice || variant.percentOfDiscount),
      energyLabel: {
        energyType: product?.variants[0]?.attributes?.energyClass?.value as EnergyLabelType,
        energyURL: product?.variants[0]?.attributes?.energyLabelUrl?.value,
      },
    } as ProductLabels;
  }, [product, isOnStock, variant]);

  const productUrl = useMemo(() => {
    if (!product._url) return '#';

    if (isSearchResult) return `${product._url}?sr=1`;
    return product._url;
  }, [product._url, isSearchResult]);

  const handleAddToCart = async () => {
    setLoadingAdd(true);

    trackAddToCartProduct(product, quantity + 1);

    addItem(variant, 1).then(() => {
      setLoadingAdd(false);

      setAdded(true);
      setTimeout(() => {
        setAdded(false);
        setQuantity(quantity + 1);
      }, 1000);
    });
  };

  // handleUpdateCartItem is used for both updating a value as result of a change in the input field and for removing an item from the cart completely
  const handleUpdateCartItem = async (newQuantity: number) => {
    if (loadingRemove) return;
    newQuantity > quantity ? setLoadingAdd(true) : setLoadingRemove(true);

    const lineItem = data?.lineItems?.find((lineItem) => lineItem.variant?.sku === variant?.sku);
    if (!lineItem?.lineItemId) return;

    if (newQuantity < 1) {
      await removeItem(lineItem.lineItemId as string);
    } else {
      trackAddToCartLineItem(lineItem, newQuantity);
      await updateItem(lineItem.lineItemId as string, newQuantity);
    }
    if (newQuantity > quantity) {
      setLoadingAdd(false);
      setAdded(true);
    } else {
      setLoadingRemove(false);
      setRemoved(true);
    }
    setTimeout(() => {
      setAdded(false);
      setRemoved(false);
      setQuantity(newQuantity);
    }, 1500);
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = +(e.target as HTMLInputElement).value;
    setInputQuantity(newQuantity);
  };

  const handleQuantityBlur = (e: React.FormEvent) => {
    const newQuantity = +(e.target as HTMLSelectElement).value;
    if (quantity === newQuantity) {
      return;
    }

    handleUpdateCartItem(newQuantity);
    setInputQuantity(0);
    quantity > newQuantity ? setLoadingRemove(true) : setLoadingAdd(true);

    const loadingTimer = setTimeout(() => {
      if (quantity > newQuantity) {
        setLoadingRemove(false);
        setRemoved(true);
      } else {
        setLoadingAdd(false);
        setAdded(true);
      }
      clearTimeout(loadingTimer);

      const addedTimer = setTimeout(() => {
        quantity > newQuantity ? setRemoved(false) : setAdded(false);
        clearTimeout(addedTimer);
      }, 500);
    }, 1500);
  };

  const cartButton = isOnStock ? (
    <CartButton
      addToCart={handleAddToCart}
      removeFromCart={() => handleUpdateCartItem(quantity - 1)}
      onChange={handleQuantityChange}
      onBlur={handleQuantityBlur}
      loadingAdd={loadingAdd}
      loadingRemove={loadingRemove}
      added={added}
      removed={removed}
      quantity={quantity}
      inputQuantity={inputQuantity}
    />
  ) : (
    <Typography className="text-14 leading-[17.5px] text-base-brand-1">
      {formatProductMessage({ id: 'noStock', defaultMessage: 'This Product is not in stock' })}
    </Typography>
  );

  const prices = (
    <Prices
      price={variantWithDiscount?.price ?? variant?.price}
      percentOfDiscount={variant?.percentOfDiscount}
      strikeThroughPrice={variant?.strikeThroughPrice}
      variant="product-tile"
      vatText={`${variant?.attributes?.packageSize?.value} ${formatProductMessage({
        id: 'baseUnit',
        defaultMessage: 'szt.',
      })} ${formatProductMessage({
        id: 'including',
        defaultMessage: 'incl.',
      })} ${formatProductMessage({ id: 'vat', defaultMessage: 'VAT' })}`}
      disabled={!isOnStock}
    />
  );

  return (
    <div
      data-test="product-tile"
      onClick={onClick}
      className={'flex min-h-full flex-col items-center bg-neutral-5 px-12 pb-12 ' + className}
    >
      {/* Desktop layout */}
      <div className="hidden w-full flex-1 sm:flex sm:flex-col">
        <Link link={productUrl} className="w-full flex-1">
          {/* Image */}
          <div
            data-test="responsive-image"
            className={`relative mb-12 flex h-188 w-full shrink-0 items-center justify-center px-69 py-13 ${
              isOnStock ? '' : 'opacity-50'
            }`}
          >
            {productLabels?.energyLabel.energyType ? <EnergyLabel energyLabel={productLabels.energyLabel} /> : null}
            <Image cloudimage={true} src={variant.images?.[0]} alt={product.name ?? ''} />
          </div>

          {/* Product name and labels */}
          <div>
            <div className="flex w-full flex-col items-start gap-8">
              <ProductTileLabels labels={productLabels} />
              <div title={product?.name} className="line-clamp-2 h-40 text-ellipsis">
                <h3 data-test="product-name" className="text-16 font-bold leading-5 text-neutral-4">
                  {product?.name}
                </h3>
              </div>
            </div>

            {/* Price */}
            {prices}
          </div>

          {/* Historical price and product card */}
          {variant.bestPrice30 && variant.strikeThroughPrice ? (
            <PromotionalInfo
              promotionalInfoText={formatProductMessage({
                id: 'bestPrice30Text',
                defaultMessage: 'Lowest price since 30 days prior to discount',
              })}
              amount={variant.bestPrice30}
            />
          ) : null}
          {productCardUrl?.value ? <ProductCard productCardUrl={productCardUrl} /> : null}
        </Link>

        {/* Wishlist and Add to Cart buttons */}
        <div className="mt-16 flex w-full items-center justify-between gap-8 self-stretch">
          {!disableWishlistButton && productToWishlistLineItem && (
            <WishlistButton lineItem={productToWishlistLineItem} className="h-48 w-48" />
          )}
          <div>{cartButton}</div>
        </div>
      </div>

      {/* Mobile layout */}
      <div className="w-full sm:hidden">
        {/* Product labels */}
        <div className="bg-neutral-5 pt-16">
          <ProductTileLabels labels={productLabels} />
        </div>
        <Link link={productUrl} className="flex w-full items-start gap-12 bg-neutral-5 pb-20 pt-12">
          <div className="flex-1">
            <div className="flex flex-col">
              {/* Product name */}
              <div className="flex w-full flex-col items-start gap-12">
                <h3 data-test="product-name" className="text-16 font-bold leading-5 text-neutral-4">
                  {product?.name}
                </h3>
              </div>
              {/* Price */}
              {prices}

              {/* Historical price */}
              {variant.bestPrice30 && variant.strikeThroughPrice ? (
                <PromotionalInfo
                  promotionalInfoText={formatProductMessage({
                    id: 'bestPrice30Text',
                    defaultMessage: 'Lowest price since 30 days prior to discount',
                  })}
                  amount={variant.bestPrice30}
                />
              ) : null}
            </div>
            {/* Product card */}
            {productCardUrl?.value ? <ProductCard productCardUrl={productCardUrl} /> : null}
          </div>
          <div className="h-150 w-150">
            {/* Image */}
            <div data-test="responsive-image" className={`relative h-full ${isOnStock ? '' : 'opacity-50'}`}>
              {productLabels?.energyLabel.energyType ? (
                <EnergyLabel energyLabel={productLabels.energyLabel} className="z-50" />
              ) : null}
              <Image cloudimage={true} src={variant.images?.[0]} alt={product.name ?? ''} />
            </div>
          </div>
        </Link>
        {/* Wishlist and Add to Cart buttons */}
        <div className="flex w-full items-center justify-between gap-8 self-stretch bg-neutral-5 pb-16">
          {!disableWishlistButton && productToWishlistLineItem && (
            <WishlistButton lineItem={productToWishlistLineItem} className="h-48 w-48" />
          )}
          <div>{cartButton}</div>
        </div>
      </div>
    </div>
  );
};
export default ProductTile;
